import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

// Foundational style overrides
import colors from "./foundations/colors";
import fonts from "./foundations/fonts";
import breakpoints from "./foundations/breakpoints";
import radius from "./foundations/radius";
import textStyles from "./foundations/textStyles";

// Custom component styles
import Button from "./components/button";
import IconButton from "./components/iconButton";
import Input from "./components/input";
import Textarea from "./components/textarea";
import Switch from "./components/switch";
import Radio from "./components/radio";
import Checkbox from "./components/checkbox";
import Tabs from "./components/tabs";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const overrides = {
  styles: {
    global: (props) => ({
      body: {
        color: mode("light.brand.strong", "dark.brand.strong")(props),
        bg: mode("light.background.strong", "dark.background.strong")(props),
      },
    }),
  },
  colors: colors,
  fonts: fonts,
  breakpoints: breakpoints,
  radii: radius,
  textStyles: textStyles,
  components: {
    Button,
    IconButton,
    Input,
    Textarea,
    Switch,
    Radio,
    Checkbox,
    Tabs,
  },
};

// We 'extend' the base theme, that means that every attribute that was not overriden will be included
const theme = extendTheme({ ...config, ...overrides });

export default theme;
