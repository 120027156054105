import firebase from "~/firebase/clientApp";
import { updateUserDetails as cloudFunctionUpdateUserDetails } from "~/actions/CloudFunctions";
import localforage from "localforage";
import { unregisterUserDeviceForPushNotifications } from "~/actions/CloudFunctions";
import {
  LF_KEY_DEVICE_ID,
  LF_KEY_PUSH_NOTIFICATIONS_FCM_TOKEN,
  LF_KEY_PUSH_NOTIFICATIONS_REGISTERED_IN_BACKEND,
  LS_KEY_AUTH_OTP_EMAIL,
  LS_KEY_AUTH_OTP_DISPLAY_NAME,
} from "~/utils/Constants";
import * as Sentry from "@sentry/nextjs";

const FIREBASE_AUTH_OTP_CALLBACK_URL =
  "https://shopstory.io/auth/confirm-email";

// Logs in a user as a temporary anonymous account
export async function loginAnonymously() {
  // Check that we are not running server side
  if (typeof window !== "undefined") {
    return firebase
      .auth()
      .signInAnonymously()
      .catch((error) => Sentry.captureException(error));
  }
}

// Logs in an account using email and password
export async function loginWithEmail(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

// Logs out currently logged in
export async function logout() {
  return localforage
    .getItem(LF_KEY_PUSH_NOTIFICATIONS_FCM_TOKEN)
    .then((fcmToken) => {
      // If the current device is subscribed to push notifications, unregister this device
      if (fcmToken) {
        return localforage
          .getItem(LF_KEY_DEVICE_ID)
          .then((deviceId) =>
            unregisterUserDeviceForPushNotifications(deviceId)
          )
          .then(() => {
            localforage.setItem(
              LF_KEY_PUSH_NOTIFICATIONS_REGISTERED_IN_BACKEND,
              false
            );
          });
      } else {
        return Promise.resolve();
      }
    })
    .then(() => firebase.auth().signOut());
}

// Performs a "passwordless" (or OTP) authentication by sending a link to the user's email
export async function authWithEmailLink(email, displayName) {
  const actionCodeSettings = {
    url: FIREBASE_AUTH_OTP_CALLBACK_URL,
    handleCodeInApp: true,
  };

  return firebase
    .auth()
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem(LS_KEY_AUTH_OTP_EMAIL, email);
      if (displayName)
        window.localStorage.setItem(LS_KEY_AUTH_OTP_DISPLAY_NAME, displayName);
    });
}

// Post validation for "passwordless" (OTP) authentication method
export async function validateEmailLink(email, link) {
  return firebase
    .auth()
    .signInWithEmailLink(email, link)
    .then((result) => {
      window.localStorage.removeItem(LS_KEY_AUTH_OTP_EMAIL);
      window.localStorage.removeItem(LS_KEY_AUTH_OTP_DISPLAY_NAME);
      return result;
    });
}

// Returns the local email stored for OTP authentications
export function getOtpLocalEmail() {
  if (typeof window !== "undefined")
    return window.localStorage.getItem(LS_KEY_AUTH_OTP_EMAIL) || null;
  else return null;
}

// Returns the local display name stored for OTP sign ups
export function getOtpLocalDisplayName() {
  if (typeof window !== "undefined")
    return window.localStorage.getItem(LS_KEY_AUTH_OTP_DISPLAY_NAME) || null;
  else return null;
}

// Performs a traditional email and password sign up
export async function signupWithEmail(email, password, displayName) {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((result) => {
      const user = result.user;
      const provider = result.additionalUserInfo.providerId;

      const userInfo = {
        userId: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        displayName: displayName,
        avatarUrl: user.photoURL,
        locale: "es",
        provider: provider,
        googleAccessToken: null,
        facebookAccessToken: null,
        twitterAccessToken: null,
      };

      return sendUserDetailsToBackend(userInfo);
    });
}

// Performs a social login with Google
export async function authWithGoogle() {
  return firebase
    .auth()
    .signInWithPopup(new firebase.auth.GoogleAuthProvider())
    .then((result) => {
      const user = result.user;
      const googleAccessToken = result.credential.accessToken;
      const provider = result.credential.providerId;
      const locale = result.additionalUserInfo.profile.locale;

      const userInfo = {
        userId: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        displayName: user.displayName,
        avatarUrl: user.photoURL,
        locale: locale,
        provider: provider,
        googleAccessToken: googleAccessToken,
        facebookAccessToken: null,
        twitterAccessToken: null,
      };

      return sendUserDetailsToBackend(userInfo);
    });
}

// Performs a social login with Facebook
export async function authWithFacebook() {
  return firebase
    .auth()
    .signInWithPopup(new firebase.auth.FacebookAuthProvider())
    .then((result) => {
      const user = result.user;
      const facebookAccessToken = result.credential.accessToken;
      const provider = result.credential.providerId;

      const userInfo = {
        userId: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        displayName: user.displayName,
        avatarUrl: user.photoURL,
        locale: "es",
        provider: provider,
        googleAccessToken: null,
        facebookAccessToken: facebookAccessToken,
        twitterAccessToken: null,
      };

      return sendUserDetailsToBackend(userInfo);
    });
}

// Performs a social login with Twitter
export async function authWithTwitter() {
  return firebase
    .auth()
    .signInWithPopup(new firebase.auth.TwitterAuthProvider())
    .then((result) => {
      const user = result.user;
      const twitterAccessToken = result.credential.accessToken;
      const provider = result.credential.providerId;

      const userInfo = {
        userId: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        displayName: user.displayName,
        avatarUrl: user.photoURL,
        locale: "es",
        provider: provider,
        googleAccessToken: null,
        facebookAccessToken: null,
        twitterAccessToken: twitterAccessToken,
      };

      return sendUserDetailsToBackend(userInfo);
    });
}

// Ensures that the users' data is stored on Firestore
export async function sendUserDetailsToBackend(userInfo) {
  return cloudFunctionUpdateUserDetails(userInfo).then(function (result) {
    return result;
  });
}
