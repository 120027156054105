export default {
  baseStyle: {
    borderRadius: "brand",
    _focus: {
      boxShadow: "0 0 0 3px rgba(52, 93, 238, .5)",
    },
  },
  defaultProps: {
    variant: "solid",
    size: "md",
    colorScheme: "primary",
  },
};
