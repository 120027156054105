// theme.js
import { createBreakpoints } from "@chakra-ui/theme-tools";
export default createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
  mobile: "320px",
  tablet: "768px",
  desktop: "992px",
});
