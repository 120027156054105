export default {
  primary: {
    50: "#99aef6",
    100: "#859df4",
    200: "#708df3",
    300: "#5c7df1",
    400: "#486def",
    500: "#345dee", // Base color
    600: "#2e53d6",
    700: "#294abe",
    800: "#2441a6",
    900: "#1f378e",
  },
  secondary: {
    50: "#f699b2",
    100: "#f485a2",
    200: "#f37093",
    300: "#f15c83",
    400: "#ef4874",
    500: "#ee3465", // Base color
    600: "#d62e5a",
    700: "#be2950",
    800: "#a62446",
    900: "#8e1f3c",
  },
  positive: {
    50: "#99da9c",
    100: "#85d288",
    200: "#70cb75",
    300: "#5cc361",
    400: "#48bc4d",
    500: "#34B53A", // Base color
    600: "#2ea234",
    700: "#29902e",
    800: "#247e28",
    900: "#1f6c22",
  },
  negative: {
    50: "#F67F82",
    100: "#F46669",
    200: "#F34C50",
    300: "#F13337",
    400: "#EF191E",
    500: "#EE0005", // Base color
    600: "#D60004",
    700: "#BE0004",
    800: "#A60003",
    900: "#8E0003",
  },
  warning: {
    50: "#F8E4A5",
    100: "#F7DE93",
    200: "#F5D981",
    300: "#F4D36F",
    400: "#F3CE5D",
    500: "#F2C94C", // Base color
    600: "#D9B444",
    700: "#C1A03C",
    800: "#A98C35",
    900: "#91782D",
  },
  brandStrong: {
    50: "#8B90A6",
    100: "#747994",
    200: "#5D6382",
    300: "#464D70",
    400: "#2F375E",
    500: "#18214d", // Base color
    600: "#151D45",
    700: "#131A3D",
    800: "#101735",
    900: "#0E132E",
  },
  brandLite: {
    50: "#9EA1B0",
    100: "#8B8EA0",
    200: "#777C91",
    300: "#646981",
    400: "#515671",
    500: "#3e4462", // Base color
    600: "#373D58",
    700: "#31364E",
    800: "#2B2F44",
    900: "#25283A",
  },
  action: {
    primary: "#345DEE",
    secondary: "#EE3465",
  },
  feel: {
    positive: "#34B53A",
    negative: "#EE0005",
    warning: "#F2C94C",
  },
  // Light mode colors
  light: {
    brand: {
      strong: "#18214D",
      lite: "#3E4462",
    },
    background: {
      strong: "#F4F4F4",
      lite: "#FBFBFB",
      superLite: "#FFFFFF",
      action: "#EFF2F6",
      separator: "#DFE4EA",
    },
  },
  // Dark mode colors
  dark: {
    brand: {
      strong: "#a6accd",
      lite: "#99a0c6",
    },
    background: {
      strong: "#090b10",
      lite: "#1a1a2e",
      superLite: "#0f111a",
      action: "#1a1c25",
      separator: "#222633",
    },
  },
};
