import { useState, useEffect, createContext, useContext } from "react";
import firebase from "~/firebase/clientApp";
import { loginAnonymously } from "~/actions/AuthActions";
import * as Sentry from "@sentry/nextjs";

export const UserContext = createContext();

export default function UserContextComp({ children }) {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true); // Helpful, to update the UI accordingly.
  useEffect(() => {
    // Listen authenticated user
    const unsubscriber = firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          // User is signed in.
          const { uid, displayName, email, photoURL } = user;
          // You could also look for the user doc in your Firestore (if you have one):
          // const userDoc = await firebase.firestore().doc(`users/${uid}`).get()
          setUser({ uid, displayName, email, photoURL });

          // Set the user on Sentry for a more accurate error reporting
          Sentry.setUser({ id: uid, username: displayName, email: email });
        } else {
          setUser(null);
          Sentry.configureScope((scope) => scope.setUser(null));
        }
      } catch (error) {
        // Most probably a connection error. Handle appropriately.
        Sentry.captureException(error);
      } finally {
        setLoadingUser(false);
      }
    });

    // Unsubscribe auth listener on unmount
    return () => unsubscriber();
  }, []);

  useEffect(() => {
    // If the current user isn't a logged user, create a temporary anonymous sign in.
    if (!loadingUser && !user) loginAnonymously();
  }, [loadingUser, user]);

  return (
    <UserContext.Provider value={{ user, setUser, loadingUser }}>
      {children}
    </UserContext.Provider>
  );
}

// Custom hook that shorhands the context!
export const useUser = () => useContext(UserContext);
