export default {
  display00: {
    letterSpacing: { mobile: "0px", desktop: "-3px" },
    fontSize: { base: "4rem", sm: "6rem", md: "8rem", lg: "10rem" },
    fontWeight: "bold",
  },
  display01: {
    letterSpacing: { mobile: "0px", desktop: "-3px" },
    fontSize: { base: "2.5rem", sm: "3.5rem", md: "4rem", lg: "4.5rem" },
    fontWeight: "semibold",
  },
  display02: {
    letterSpacing: { mobile: "0px", desktop: "-2.4px" },
    fontSize: { base: "2rem", sm: "2.5rem", md: "3rem", lg: "3.5rem" },
    fontWeight: "semibold",
  },
  display03: {
    letterSpacing: { mobile: "0px", desktop: "-1.5px" },
    fontSize: { base: "1.5rem", sm: "1.5rem", md: "2rem", lg: "2.25rem" },
    fontWeight: "semibold",
  },
  label01: {
    letterSpacing: { mobile: "0px", desktop: "-0.55px" },
    fontSize: "1.375rem",
    fontWeight: "semibold",
  },
  label02: {
    letterSpacing: { mobile: "0px", desktop: "-0.4px" },
    fontSize: "1rem",
    fontWeight: "semibold",
  },
  button01: {
    letterSpacing: { mobile: "0px", desktop: "-0.5px" },
    fontSize: "1.25rem",
    fontWeight: "semibold",
  },
  button02: {
    letterSpacing: { mobile: "0px", desktop: "-0.4px" },
    fontSize: "1.125rem",
    fontWeight: "semibold",
  },
  button03: {
    letterSpacing: { mobile: "0px", desktop: "-0.4px" },
    fontSize: "1rem",
    fontWeight: "semibold",
  },
  paragraph01: {
    letterSpacing: { mobile: "0px", desktop: "-0.4px" },
    fontSize: "1.25rem",
    fontWeight: "normal",
  },
  paragraph02: {
    letterSpacing: { mobile: "0px", desktop: "-0.3px" },
    fontSize: "1.125rem",
    fontWeight: "normal",
  },
  paragraph03: {
    letterSpacing: { mobile: "0px", desktop: "-0.2px" },
    fontSize: "1rem",
    fontWeight: "normal",
  },
  paragraph04: {
    letterSpacing: { mobile: "0px", desktop: "-0.4px" },
    fontSize: "0.875rem",
    fontWeight: "normal",
  },
};
