import { useEffect } from "react";
import localforage from "localforage";
import { v4 as uuidv4 } from "uuid";
import { LF_KEY_DEVICE_ID } from "~/utils/Constants";
import { Integrations } from "@sentry/tracing";
import UserProvider from "../context/userContext";
import { ChakraProvider } from "@chakra-ui/react";
import ShopStoryTheme from "~/theme/";
import * as Sentry from "@sentry/nextjs";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: "shop-story-web@" + process.env.npm_package_version,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1, // Each transaction has a 10% chance of being sent to Sentry
  });
}

// Custom CSS required for the React Grid Layout dependency
import "~/styles/react-grid-layout-styles.css";
import "~/styles/react-resizable-styles.css";

// Custom App to wrap it with context provider
export default function App({ Component, pageProps }) {
  // Intialize a device ID and store it with localforage so it can be used for different purpouses (like notifications)
  // where a device ID is needed
  useEffect(() => {
    localforage.getItem(LF_KEY_DEVICE_ID).then((deviceId) => {
      if (!deviceId) localforage.setItem(LF_KEY_DEVICE_ID, uuidv4());
    });
  }, []);

  return (
    <div>
      <UserProvider>
        <ChakraProvider theme={ShopStoryTheme}>
          <Component {...pageProps} />
        </ChakraProvider>
      </UserProvider>

      {/*
      Sets CSS styles so that each page occupies the whole height of the viewport. This is neccesary so that childs
      of the Page component can set the height with a %. Without this code that way of setting the height won't work
      because Next wraps the whole app with other internal divs.
      Source: https://gist.github.com/dmurawsky/d45f068097d181c733a53687edce1919
      */}
      <style global jsx>{`
        html,
        body,
        body > div:first-child,
        div#__next,
        div#__next > div,
        div#__next > div > div {
          height: 100%;
        }
      `}</style>
    </div>
  );
}
