export default {
  baseStyle: {
    borderRadius: "brand",
  },
  defaultProps: {
    size: "md",
    variant: "outline",
    focusBorderColor: "action.primary",
    errorBorderColor: "feel.negative",
  },
};
