import firebase from "~/firebase/clientApp";

const FUNCTION_CHECK_DOMAIN_AVAILABILITY = "onboarding-checkDomainAvailability";
const FUNCTION_CREATE_SHOP = "onboarding-createShop";
const FUNCTION_UPDATE_USER_DETAILS = "users-updateUserDetails";
const FUNCTION_INVITE_USER_TO_SHOP = "users-inviteUserToShop";
const FUNCTION_REGISTER_USER_DEVICE_FOR_PUSH_NOTIFICATIONS =
  "users-registerDeviceForPushNotifications";
const FUNCTION_UNREGISTER_USER_DEVICE_FOR_PUSH_NOTIFICATIONS =
  "users-unregisterDeviceForPushNotifications";
const FUNCTION_CHANGE_SHOP_MEMBER_ROLE = "users-changeShopTeamMemberRole";
const FUNCTION_REMOVE_SHOP_MEMBER = "users-removeTeamMemberFromShop";
const FUNCTION_CHECKOUT_CART = "orders-checkoutCart";
const FUNCTION_MERCADOPAGO_GET_LINK_TOKEN = "mercadopago-getLinkToken";
const FUNCTION_MERCADOPAGO_VERIFY_LINK_TOKEN = "mercadopago-verifyLinkToken";
const FUNCTION_MERCADOPAGO_LINK_ACCOUNT = "mercadopago-linkAccount";
const FUNCTION_MERCADOPAGO_UNLINK_ACCOUNT = "mercadopago-unlinkAccount";

export function checkDomainAvailability(domain) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_CHECK_DOMAIN_AVAILABILITY);

  return cloudFunction({ domain: domain });
}

export function createShop(onboardingData) {
  let cloudFunction = firebase.functions().httpsCallable(FUNCTION_CREATE_SHOP);

  return cloudFunction({ onboardingData: onboardingData });
}

/*
REQUEST PAYLOAD:

{
    email: example@test.com,
    emailVerified: true,
    displayName : "Test Name",
    avatarUrl: "https://example.com/image.jpg",
    locale: "es",
    provider: "google",
    googleAccessToken: "ABC123",
    facebookAccessToken: null,
    twitterAccessToken: null,
}
*/
export function updateUserDetails(userDetails) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_UPDATE_USER_DETAILS);

  return cloudFunction({ userDetails: userDetails });
}

export function registerUserDeviceForPushNotifications(deviceId, fcmToken) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_REGISTER_USER_DEVICE_FOR_PUSH_NOTIFICATIONS);

  return cloudFunction({ deviceId: deviceId, fcmToken: fcmToken });
}

export function unregisterUserDeviceForPushNotifications(deviceId) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_UNREGISTER_USER_DEVICE_FOR_PUSH_NOTIFICATIONS);

  return cloudFunction({ deviceId: deviceId });
}

export async function inviteUserToShop(shopId, email, role) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_INVITE_USER_TO_SHOP);

  return cloudFunction({
    invite: {
      shopId: shopId,
      email: email,
      role: role,
    },
  });
}

export async function changeShopTeamMemberRole(shopId, userId, newRole) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_CHANGE_SHOP_MEMBER_ROLE);

  return cloudFunction({
    roleUpdate: {
      shopId: shopId,
      userId: userId,
      newRole: newRole,
    },
  });
}

export async function removeTeamMemberFromShop(shopId, userId) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_REMOVE_SHOP_MEMBER);

  return cloudFunction({
    removeUpdate: {
      shopId: shopId,
      userId: userId,
    },
  });
}

// Performs a checkout for the products that are currently in the users' cart.
export async function checkoutCart(checkoutData) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_CHECKOUT_CART);

  return cloudFunction({
    checkoutData: checkoutData,
  });
}

// Retrieves a 'Link Token' (JWT) to initiate an external auth with MercadoPago
export async function getMercadoPagoLinkToken(shopId) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_MERCADOPAGO_GET_LINK_TOKEN);

  return cloudFunction({
    credentials: {
      shopId: shopId,
    },
  });
}

// Returns the decoded Link Token JWT or returns an error if the token is invalid
export async function linkMercadoPagoAccount(linkToken, authorizationCode) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_MERCADOPAGO_LINK_ACCOUNT);

  return cloudFunction({
    credentials: {
      linkToken: linkToken,
      authorizationCode: authorizationCode,
    },
  });
}

// Unlinks a shop from its associated MercadoPago account
export async function unlinkMercadoPagoAccount(shopId) {
  let cloudFunction = firebase
    .functions()
    .httpsCallable(FUNCTION_MERCADOPAGO_UNLINK_ACCOUNT);

  return cloudFunction({
    credentials: {
      shopId: shopId,
    },
  });
}
