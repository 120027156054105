import {
  FIREBASE_CLIENT_CREDENTIALS,
  LS_KEY_FIREBASE_LOCAL_CACHE_VERSION,
} from "~/utils/Constants";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";
import "firebase/performance";
import "firebase/messaging";
import * as Sentry from "@sentry/nextjs";

const LATEST_LOCAL_CACHE_VERSION = 1;

// Check that `window` is in scope for the analytics module!
if (typeof window !== "undefined" && !firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CLIENT_CREDENTIALS);
  firebase.analytics();
  firebase.performance();

  setUpFirestoreLocalCache();
}

function setUpFirestoreLocalCache() {
  const localCacheVersion =
    window.localStorage.getItem(LS_KEY_FIREBASE_LOCAL_CACHE_VERSION) || 0;

  // This mechanism providers a way to invalidate all caches.
  // This is neccessary when there is a heavy change on a document schema thay may not be
  // fully compatible with changes on the frontend.
  if (localCacheVersion < LATEST_LOCAL_CACHE_VERSION) {
    firebase.firestore().clearPersistence();
    window.localStorage.setItem(
      LS_KEY_FIREBASE_LOCAL_CACHE_VERSION,
      LATEST_LOCAL_CACHE_VERSION
    );
  }

  //Enable offline persitance
  firebase
    .firestore()
    .enablePersistence({ synchronizeTabs: true })
    .then(() => Sentry.setContext("firestore-offline-cache-enabled", true))
    .catch((error) => {
      Sentry.setContext("firestore-offline-cache-enabled", false);
      Sentry.setTag("firestore-offline-cache-error", error.code);
    });
}

export default firebase;
