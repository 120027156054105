// Firebase credentials
// Env variables prefixed with NEXT_PUBLIC_ are inlined and sent to the browser.
// Those public env vars should be declared in both the server and on the local .env.local file.
// https://nextjs.org/docs/basic-features/environment-variables
export const FIREBASE_CLIENT_CREDENTIALS = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

// Misc
export const CONFIRM_CHANGES_DELAY = 2000;
export const ANONYMOUS_USER_SHORT_ID_LENGTH = 8;
export const PRODUCT_SLUG_UID_LENGTH = 6;
export const DEFAULT_PRODUCT_STYLE = "compact";
export const DEFAULT_SHOP_HEADER_STYLE = "boxed";
export const DEFAULT_SHOP_FOOTER_STYLE = "detailed";

// Visitor Actions
export const MAX_VISITOR_ACTIONS_RECORDS = 10; // How many actions will be persisted on the visitors' records on the backend
export const VISITOR_ACTION_TYPE_VIEW_PRODUCT = "view_product";
export const VISITOR_ACTION_TYPE_VIEW_COLLECTION = "view_collection";
export const VISITOR_ACTION_TYPE_VIEW_CHECKOUT = "view_checkout";
export const VISITOR_ACTION_TYPE_VIEW_ABOUT_SHOP = "view_about_shop";
export const VISITOR_ACTION_TYPE_VIEW_TAGS_SEARCH_RESULTS =
  "view_tags_search_results";

// This key is stored here because it can't be saved as an environemnt variable, since it needs to be accesed by
// firebase-messaging-sw.js service worker, and due to webpack stuff if can't access env vars.
export const CLOUD_MESSAGING_PUBLIC_VAPID_KEY =
  "BLUplSF0SEDvL8xYU3flsnaKTg8V3teeKHlAIcyoYr6p6ACga40VmcIedXOG1560wQPdvVHRxvSDkbB2fgo0hfQ";

// Localforage Keys
export const LF_KEY_DEVICE_ID = "device_id";
export const LF_KEY_PUSH_NOTIFICATIONS_FCM_TOKEN =
  "push_notifications_fcm_token";
export const LF_KEY_PUSH_NOTIFICATIONS_REGISTERED_IN_BACKEND =
  "push_notifications_registered_in_backend";
export const LF_KEY_PUSH_NOTIFICATIONS_BANNER_DO_NOT_REMIND =
  "push_notifications_do_not_remind";
export const LF_KEY_PUSH_NOTIFICATIONS_GLOBAL_NOTIFICATIONS_ENABLED =
  "push_notifications_global_notifications_enabled";
export const LF_KEY_PUSH_NOTIFICATIONS_NEW_VISITOR_ONLINE_NOTIFICATION_DISPLAY =
  "push_notifications_new_visitor_online_notification_display";
export const LF_KEY_PUSH_NOTIFICATIONS_NEW_VISITOR_ONLINE_NOTIFICATION_SOUND =
  "push_notifications_new_visitor_online_notification_sound";
export const LF_KEY_PUSH_NOTIFICATIONS_NEW_VISITOR_MESSAGE_NOTIFICATION_DISPLAY =
  "push_notifications_new_visitor_message_notification_display";
export const LF_KEY_PUSH_NOTIFICATIONS_NEW_VISITOR_MESSAGE_NOTIFICATION_SOUND =
  "push_notifications_new_visitor_message_notification_sound";
export const LF_KEY_PUSH_NOTIFICATIONS_NEW_ORDER_NOTIFICATION_DISPLAY =
  "push_notifications_new_order_notification_display";
export const LF_KEY_PUSH_NOTIFICATIONS_NEW_ORDER_NOTIFICATION_SOUND =
  "push_notifications_new_order_notification_sound";
export const LF_KEY_SHOWCASE_EDITOR_GRID_MODE =
  "control_showcase_editor_grid_mode";

// LocalStorage Keys
export const LS_KEY_AUTH_OTP_EMAIL = "auth_otp_email";
export const LS_KEY_AUTH_OTP_DISPLAY_NAME = "auth_otp_display_name";
export const LS_KEY_FIREBASE_LOCAL_CACHE_VERSION =
  "firebase_local_cache_version";

// Push Notifications
export const PUSH_NOTIFICATION_TOAST_DURATION = 4500;
export const PUSH_NOTIFICATION_TYPE_NEW_VISITOR_ONLINE = "new_visitor_online";
export const PUSH_NOTIFICATION_TYPE_NEW_VISITOR_MESSAGE = "new_visitor_message";
export const PUSH_NOTIFICATION_TYPE_NEW_ORDER = "new_order";

// Algolia
export const ALGOLIA_PROJECT_ID = "8M9GTM2WXJ";
export const ALGOLIA_PUBLIC_API_KEY = "cdcefba43cc3d5361dcddac51979d3a2";

// MercadoPago
export const MERCADOPAGO_APP_ID = "6912638673745104";
export const MERCADOPAGO_LINK_ACCOUNT_REDIRECT_URL =
  "https://www.shopstory.io/auth/mercadopago";
